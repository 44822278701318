<template>
  <!-- form -->
  <div>
    <Modal
      :id="modalOptions.clickedId"
      ref="confirmModal"
      :text="modalOptions.text"
      :ok-button="modalOptions.okButton"
      :cancel-button="modalOptions.cancelButton"
      :show="show"
      @result="modalResult"
      @hidden="show = false"
    />
    <div class="card">
      <div class="card-header">
        <h3>{{ $t(formTitle) }}</h3>
      </div>
      <div class="card-body">
        <div class="activity-form-help">
          <p>{{ $t('activity_help_3') }}</p>
          <small v-if="!userCan($store.state.auth.user.plan, 'widget')">{{
            $t('activity_help_embed')
          }}</small>
          <br />
        </div>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <BCol md="6">
                <BRow>
                  <b-col md="12">
                    <b-form-group label-for="name" :label="$t(typeName + ' name')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t(typeName + ' name')"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="formData.name"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t(typeName + ' name')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="description-col">
                    <b-form-group label-for="details" :label="$t('Description')">
                      <div class="form-help">
                        <feather-icon
                          id="desc_help"
                          icon="HelpCircleIcon"
                          class="icon-button"
                          size="18"
                        />
                        <b-tooltip target="desc_help" triggers="hover">
                          {{ $t('activity_desc_help') }}
                        </b-tooltip>
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="$t('Description')"
                      >
                        <quill-editor
                          id="details"
                          v-model="formData.details"
                          :options="editorOptions"
                          :state="errors.length > 0 ? false : null"
                          :class="errors.length > 0 ? 'border-danger-for-text-editor' : ''"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- keywords -->
                  <b-col v-if="userCan($store.state.auth.user.plan, 'serp_priority')" cols="12">
                    <b-form-group label-for="keywords" :label="$t('Keywords')">
                      <div class="form-help">
                        <feather-icon
                          id="keyword_help"
                          icon="HelpCircleIcon"
                          class="icon-button"
                          size="18"
                        />
                        <b-tooltip target="keyword_help" triggers="hover">
                          {{ $t('keyword_help') }}
                        </b-tooltip>
                      </div>
                      <b-form-input
                        id="keywords"
                        v-model="formData.keywords"
                        :placeholder="$t('...')"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group label-for="class-type" :label="$t('Class type')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Class type')"
                        rules="required"
                      >
                        <v-select
                          id="class-type"
                          v-model="formData.category_options"
                          multiple
                          :loading="classTypeLoader"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :placeholder="$t('Class type')"
                          label="name"
                          :options="classTypeList"
                          :reduce="option => option.id"
                          :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group label-for="age" :label="$t('Age')">
                      <validation-provider #default="{ errors }" :name="$t('Age')" rules="required">
                        <vue-slider
                          id="age"
                          v-model="ageRange"
                          class="mb-2 mt-3"
                          :min="0"
                          :max="19"
                          :marks="marks"
                          :included="true"
                          :hide-label="true"
                          :process-style="{ backgroundColor: '#6BC4C5' }"
                          :tooltip-style="{
                            backgroundColor: '#F8F8F8',
                            borderColor: '#F8F8F8',
                            color: '#0E1A1A',
                            fontWeight: '700',
                          }"
                          :tooltip="'always'"
                          :state="errors.length > 0 ? false : null"
                        >
                          <template #tooltip="{ index, value }">
                            <div v-if="value === 0.5" class="tooltip-slider">
                              6 {{ $t('months') }}
                            </div>
                            <div v-if="value < 0.5" class="tooltip-slider">
                              {{ $t('New born') }}
                            </div>
                            <div v-if="value >= 1 && value < 19" class="tooltip-slider">
                              {{ value }}
                              {{ value === 1 ? $t('year') : $t('years') }}
                            </div>
                            <div v-if="value == 19" class="tooltip-slider">
                              {{ $t('Adults') }}
                            </div>
                          </template>
                        </vue-slider>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label-for="location" :label="$t('Location')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Location')"
                        rules="required"
                      >
                        <v-select
                          id="location"
                          v-model="formData.location_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :placeholder="$t('Location')"
                          label="name"
                          :loading="locationLoader"
                          :options="locationsList"
                          :reduce="option => option.id"
                          :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                          :state="errors.length > 0 ? false : null"
                        >
                          <template #list-footer>
                            <li class="text-center d-flex">
                              <b-link
                                class="cursor-pointer"
                                style="margin: auto; padding: 0.3rem; width: 100%"
                                :to="{ name: 'create-location' }"
                              >
                                {{ $t('Add new location') }}
                              </b-link>
                            </li>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label-for="language" :label="$t('Language')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Language')"
                        rules="required"
                      >
                        <v-select
                          id="language"
                          v-model="formData.language_options"
                          multiple
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :placeholder="$t('Language')"
                          label="language"
                          :loading="locationLoader"
                          :options="languagesList"
                          :reduce="option => option.id"
                          :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <DropZone
                      :label="'Add photos (max 10)'"
                      :files-thumbnail="formData.filesThumbnail"
                      :files-i-ds="formData.files"
                      @data-changed="changeFilesIDs"
                    />

                    <validation-provider #default="{ errors }" name="photos" rules="required">
                      <b-form-input id="" v-model="filesInput" class="d-none" placeholder="Photo" />
                      <small class="text-danger d-flex mb-1" style="margin-top: -1rem">{{
                        errors[0]
                      }}</small>
                      <span class="files-help">
                        <p>{{ $t('files-help-3') }}</p>
                        <p>{{ $t('files-help-2') }}</p>
                        <p>{{ $t('files-help-4') }}</p>
                      </span>
                    </validation-provider>
                  </b-col>
                </BRow>
              </BCol>
              <BCol md="6">
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <b-form-checkbox
                        v-model="custom_schedule"
                        name="check-button"
                        value="1"
                        style="max-width: 80%"
                        unchecked-value="0"
                        switch
                      >
                        {{ $t('Custom schedule') }}
                      </b-form-checkbox>
                      <div class="form-help">
                        <feather-icon
                          id="custom_help"
                          icon="HelpCircleIcon"
                          class="icon-button"
                          size="18"
                        />
                        <b-tooltip target="custom_help" triggers="hover">
                          {{ $t('custom_schedule_help') }}
                        </b-tooltip>
                      </div>
                    </b-form-group>

                    <b-form-group
                      v-if="!custom_schedule || custom_schedule == '0'"
                      label-for="from_date"
                      :label="$t('Event date')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Event date')"
                        rules="required"
                      >
                        <date-picker
                          v-model="formData.from_date"
                          format="DD/MM/YYYY"
                          type="date"
                          :disabled-date="disableDateAfter"
                          class="w-100"
                          :class="errors.length > 0 ? 'border-danger-for-time-picker' : ''"
                          value-type="format"
                          :placeholder="$t('Event date')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      v-if="custom_schedule == '1'"
                      label-for="custom_additional_info"
                      :label="$t('Event date')"
                    >
                      <b-form-textarea
                        id="custom_additional_info"
                        v-model="formData.custom_additional_info"
                        rows="4"
                      />
                      <small class="block mt-1 help_text">{{ $t('custom_schedule_help') }}</small>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <div class="d-flex form-group mt-1 flex-column flex-md-row">
                      <span class="d-inline-flex align-items-center mr-auto">{{ $t('Time') }}</span>
                      <b-form-group
                        label-for="from-time"
                        class="d-inline-flex align-items-center mb-0 custom-form-group position-relative"
                        :label="$t('From')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('From')"
                          rules="required"
                        >
                          <date-picker
                            id="from-time"
                            v-model="formData.from_time"
                            type="time"
                            value-type="format"
                            format="HH:mm"
                            :disabled-time="disableFromTime"
                            :state="errors.length > 0 ? false : null"
                            :class="errors.length > 0 ? 'border-danger-for-time-picker' : ''"
                            class="ml-2 custom-style"
                            :editable="false"
                            :minute-step="5"
                          />
                          <small class="position-absolute text-danger custom-error-style">{{
                            errors[0]
                          }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label-for="to-time"
                        class="d-inline-flex align-items-center mb-0 custom-form-group position-relative"
                        :label="$t('To')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('To')"
                          rules="required"
                        >
                          <date-picker
                            id="to-time"
                            v-model="formData.to_time"
                            format="HH:mm"
                            :minute-step="5"
                            :disabled-time="disableToTime"
                            type="time"
                            value-type="format"
                            :state="errors.length > 0 ? false : null"
                            :class="errors.length > 0 ? 'border-danger-for-time-picker' : ''"
                            class="ml-2 custom-style"
                            :editable="false"
                          />
                          <small class="position-absolute text-danger custom-error-style">{{
                            errors[0]
                          }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="12">
                    <hr />

                    <b-form-group
                      id="check-price-options"
                      label-for="payment-options"
                      class="option-label"
                      :label="$t('Payment options')"
                    >
                      <PriceOptions
                        v-for="(option, i) in formData.price_options"
                        :id="'priceOption' + i"
                        :key="i"
                        :ref="'priceOption' + i"
                        label="Payment option"
                        :value="option"
                        :option-index="i"
                        :billable="!!formData.billable"
                        @optionChanged="changePriceData($event, option)"
                        @deleteOption="deletePriceOption"
                      />
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="ml-auto mb-1"
                        variant="outline-primary"
                        @click="addNewPriceOption"
                      >
                        + {{ $t('Add new payment option') }}
                      </b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </BCol>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  type="submit"
                  class="mr-2 mt-1 draft-button"
                  :disabled="submitLoader || !hasChanged"
                  @click.prevent="validationForm(true)"
                >
                  <feather-icon icon="SaveIcon" class="icon-button" size="16" />
                  {{ $t('Save as draft') }}
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                  class="mr-2 mt-1 publish-button"
                  :disabled="submitLoader"
                  @click.prevent="validationForm(false)"
                >
                  <feather-icon icon="SendIcon" class="icon-button" size="16" />
                  {{ $t('Publish') }}
                </b-button>
                <b-button
                  v-if="itemId !== -1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mt-1 delete-button"
                  type="submit"
                  @click.prevent="$router.go(-1)"
                >
                  <feather-icon icon="Trash2Icon" class="icon-button" size="16" />
                  {{ itemId !== -1 ? $t('Delete') : $t('Discard') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BLink,
  BTab,
  BTabs,
  BFormCheckbox,
  BFormTextarea,
  BTooltip,
} from 'bootstrap-vue'
import { required } from '@/libs/validations'
import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import DropZone from '@/components/DropZone'
import ActivityService from '@/services/ActivityService'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import PriceOptions from '@/components/PriceOptions'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@/components/ToastificationContent'
import Modal from '@/components/Modal'
import userCan from '@/libs/user-can'
import { editorConfig } from '@/libs/editor-config'

export default {
  name: 'EventForm',
  components: {
    Modal,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    VueSlider,
    quillEditor,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BForm,
    BRow,
    BCol,
    BButton,
    BTooltip,
    DropZone,
    DatePicker,
    PriceOptions,
    BFormCheckbox,
    BFormTextarea,
    BLink,
    BTab,
    BTabs,
  },
  directives: {
    Ripple,
  },
  props: {
    formTitle: {
      type: String,
      default: '',
      required: true,
    },
    typeName: {
      type: String,
      default: '',
      required: true,
    },
    typeId: {
      type: Number,
      required: true,
    },
    itemId: {
      type: Number,
      default: -1,
    },
    formData: {
      type: Object,
      default: () => {
        return {
          name: '',
          min_age: 4,
          max_age: 11,
          from_time: null,
          to_time: null,
          from_date: null,
          to_date: null,
          price_options: [],
          status: 'published',
          location_id: '',
          user_id: '',
          language_options: [],
          category_options: [],
          details: undefined,
          keywords: '',
          billable: false,
          files: [],
          custom_schedule: false,
          custom_additional_info: null,
          filesThumbnail: [],
        }
      },
    },
  },

  data() {
    return {
      userLang: this.$store.state.appConfig.getLang(),
      userCan: userCan,
      modalOptions: {
        text: '',
        okButton: '',
        cancelButton: '',
        clickedId: 0,
        currentAction: '',
      },
      show: false,
      skipOnceInEditPage: this.itemId !== -1,
      hasChanged: false,
      priceChange: false,
      initFormStat: JSON.parse(JSON.stringify(this.formData)),
      custom_schedule: false,
      editorOptions: editorConfig,
      ActivityService: new ActivityService(),
      required,
      marks: [0, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
      locationsList: [],
      providersList: [],
      languagesList: [],
      classTypeList: [],
      classTypeLoader: true,
      locationLoader: true,
      providerLoader: true,
      languageLoader: true,
      submitLoader: false,
      defaultPriceData: {
        id: -1,
        price: '',
        price_unit: 'Hour',
        price_info: '',
      },
    }
  },
  computed: {
    ageRange: {
      get() {
        return [this.formData.min_age, this.formData.max_age]
      },
      set(newName) {
        this.formData.min_age = newName[0]
        this.formData.max_age = newName[1]
        return [this.formData.min_age, this.formData.max_age]
      },
    },
    filesInput() {
      return this.formData.files?.length ? 'dummy text' : ''
    },
    fromTime: {
      get() {
        let from = this.formData.from_time ? this.formData.from_time.split(':') : null
        if (from)
          if (+from[0] > 12) {
            from =
              (+from[0] - 12 > 9 ? +from[0] - 12 : '0' + (+from[0] - 12).toString()) +
              ':' +
              from[1] +
              ' pm'
          } else {
            from = (+from[0] > 9 ? +from[0] : '0' + (+from[0]).toString()) + ':' + from[1] + ' am'
          }
        return from
      },
      set(newTime) {
        if (newTime) {
          let convertedDate = newTime.split(' ')
          if (newTime.includes('pm')) {
            convertedDate = convertedDate[0].split(':')
            convertedDate = +convertedDate[0] + 12 + ':' + convertedDate[1]
          } else {
            convertedDate = convertedDate[0].split(':')
            convertedDate = +convertedDate[0] + ':' + convertedDate[1]
          }
          this.formData.from_time = convertedDate
        } else {
          this.formData.from_time = null
        }
        return newTime
      },
    },
    toTime: {
      get() {
        let to = this.formData.to_time ? this.formData.to_time.split(':') : null
        if (to)
          if (+to[0] > 12) {
            to =
              (+to[0] - 12 > 9 ? +to[0] - 12 : '0' + (+to[0] - 12).toString()) + ':' + to[1] + ' pm'
          } else {
            to = (+to[0] > 9 ? +to[0] : '0' + (+to[0]).toString()) + ':' + to[1] + ' am'
          }
        return to
      },
      set(newTime) {
        if (newTime) {
          let convertedDate = newTime.split(' ')
          if (newTime.includes('pm')) {
            convertedDate = convertedDate[0].split(':')
            convertedDate = +convertedDate[0] + 12 + ':' + convertedDate[1]
          } else {
            convertedDate = convertedDate[0].split(':')
            convertedDate = +convertedDate[0] + ':' + convertedDate[1]
          }
          this.formData.to_time = convertedDate
        } else {
          this.formData.to_time = null
        }
        return newTime
      },
    },
  },
  watch: {
    formData: {
      handler(val, old) {
        if (this.priceChange) {
          this.$emit('formChanged', true)
          this.hasChanged = true
          return
        }
        const formChanged = Object.keys(this.formData).some(field => {
          if (Array.isArray(this.initFormStat[field])) {
            return this.formData[field].length !== this.initFormStat[field].length
          } else {
            return this.formData[field] !== this.initFormStat[field]
          }
        })
        this.$emit('formChanged', formChanged)
        this.hasChanged = formChanged
      },
      deep: true,
    },
    custom_schedule: function (val, old) {
      if (!val) this.formData.custom_schedule = null
    },
  },
  mounted() {
    this.getLocationsList()
    this.getLanguagesList()
    this.getCategoriesList()
    if (this.formData.custom_schedule?.length) {
      this.custom_schedule = '1'
    }
  },
  methods: {
    disableDateBefore(date) {
      const from = new Date(this.formData.from_date)
      from.setHours(0, 0, 0, 0)

      return date < from
    },
    disableDateAfter(date) {
      const to = new Date(this.formData.to_date)
      to.setHours(0, 0, 0, 0)

      return this.formData.to_date ? date > to : false
    },
    addNewPriceOption() {
      this.formData.price_options.push({ ...this.defaultPriceData })
      if (!this.initFormStat.price_options.length) {
        this.initFormStat.price_options.push({ ...this.defaultPriceData })
      }
      const index = 'priceOption' + (this.formData.price_options.length - 1)
      setTimeout(() => {
        this.$refs[index][0].$el.getElementsByClassName('editOptionFromParent')[0].click()
      }, 50)
    },
    changePriceData(event, price) {
      price.price = event.price
      price.price_info = event.price_info
      if (event.price_unit != '') {
        price.price_unit = event.price_unit
      } else {
        price.price_unit = event.price_custom
      }
      price.display_price = `${price.price / 100} €`
      this.priceChange = true
    },
    deletePriceOption(index) {
      this.formData.price_options.splice(index, 1)
      this.priceChange = true
    },
    getLocationsList() {
      this.locationLoader = true
      this.ActivityService.getLocationsList().then(res => {
        this.locationLoader = false
        this.locationsList = res.data
      })
    },
    getLanguagesList() {
      this.languageLoader = true
      this.ActivityService.getLanguagesList().then(res => {
        this.languageLoader = false
        this.languagesList = res.data
      })
    },
    getCategoriesList() {
      this.classTypeLoader = true
      this.ActivityService.getCategoriesList().then(res => {
        this.classTypeLoader = false
        this.classTypeList = res.data
      })
    },
    changeFilesIDs(e) {
      this.formData.files = e
    },
    validationForm(draft) {
      this.formData.to_date = this.formData.from_date
      if (draft) {
        this.draft(this.formData)
        return
      }
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // price editing still open
          const isOptionInEditMode = document
            .getElementById('check-price-options')
            .getElementsByClassName('editMode').length
          if (isOptionInEditMode > 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('Error'),
                icon: 'AlertTriangleIcon',
                text: this.$t('Save all Payment options before Submit'),
                variant: 'danger',
              },
            })
            return
          }
          this.submit(this.formData)
        }
      })
    },
    submit(form) {
      this.submitLoader = true
      form.type_id = +this.typeId
      // if itemId passed from parent that means the user in the edit page.
      const formKeysOnly = Object.assign({}, form)
      // remove unused keys before sent request
      delete formKeysOnly.filesThumbnail
      formKeysOnly.price_options = formKeysOnly.price_options.filter(option => {
        option.id = undefined
        return option.price !== ''
      })
      formKeysOnly.status = 'published'
      this.sendApi(formKeysOnly)
    },
    draft(form = this.formData) {
      this.submitLoader = true
      form.type_id = +this.typeId
      // if itemId passed from parent that means the user in the edit page.
      const formKeysOnly = Object.assign({}, form)
      // remove unused keys before sent request
      delete formKeysOnly.filesThumbnail
      formKeysOnly.price_options = formKeysOnly.price_options.filter(option => {
        option.id = undefined
        return option.price !== ''
      })
      formKeysOnly.status = 'draft'
      this.sendApi(formKeysOnly)
    },
    isPendingReview(res) {
      if (res.status === 'draft' && this.formData.status === 'published') {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: this.$t('Pending review'),
              icon: 'AlertTriangleIcon',
              text: this.$t(
                'Your activity is pending review. We will soon publish it for you in the marketplace!'
              ),
              variant: 'success',
            },
          },
          {
            timeout: 15_000,
          }
        )
      }
    },
    sendApi(formData) {
      if (this.itemId === -1) {
        this.$posthog.capture('Event created', { name: formData.name })
        this.ActivityService.create(formData).then(
          res => {
            this.isPendingReview(res)
            this.submitLoader = false
            this.$emit('formChanged', false)
            if (this.modalOptions.goToPath) {
              this.$router.push({ name: this.modalOptions.goToPath })
            } else {
              this.$router.push({ name: 'events' })
            }
          },
          error => {
            this.submitLoader = false
          }
        )
      } else {
        this.$posthog.capture('Event updated', {
          name: formData.name,
        })
        this.ActivityService.update(this.itemId, formData).then(
          res => {
            this.isPendingReview(res)
            this.submitLoader = false
            this.$emit('formChanged', false)
            this.$router.push({ name: 'events' })
          },
          error => {
            this.submitLoader = false
            console.log(error)
          }
        )
      }
    },
    modalResult(value) {
      this.show = false
      // to stop the before route leave validation in the create activity component
      this.$emit('formChanged', false)
      if (value) {
        this.draft(this.formData)
      } else {
        this.$router.push({ name: this.modalOptions.goToPath })
      }
    },
    disableFromTime(date) {
      if (this.formData.to_time) {
        const newDate = new Date()
        newDate.setHours(
          this.formData.to_time.split(':')[0],
          +this.formData.to_time.split(':')[1] - 1
        )
        return date.getTime() > newDate.getTime()
      }
      return false
    },
    disableToTime(date) {
      if (this.formData.from_time) {
        const newDate = new Date()
        newDate.setHours(
          this.formData.from_time.split(':')[0],
          +this.formData.from_time.split(':')[1] + 1
        )
        return date.getTime() < newDate.getTime()
      }
      return false
    },
  },
}
</script>
<style lang="scss">
.ql-editor {
  min-height: 100px;
}

.custom-form-group > div,
.custom-form-group > div > span {
  display: contents;
}

.custom-form-group > label {
  margin-left: auto;
}

.custom-error-style {
  right: 0;
  bottom: -1.5rem;
}

.option-label > label {
  //color: var(--primary);
  font-weight: 600;
  font-size: 20px;
}

.custom-style {
  width: 60%;
}

.help_text {
  font-size: 0.85em;
  color: #555;
  display: block;
}
</style>
